// @flow
import React, { useState } from "react";
import {
  ComposedChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, Line,
} from "recharts";
import { useTheme } from "@mui/styles";
import type { Node } from "react";
import type { ItemData } from "../../services/dashboardApi";
import XAxisContent from "./XAxisContent";
import GlowShadowFilter from "../GlowShadowFilter";
import LinearGradient from "../LinearGradient";
import type { LineInfoType } from "./types/Chart.types";

export const formatDate = (v: string): string => v;
export const formatNumber = (v: number): string => (v >= 1000 ? `${v / 1000}k` : String(v));

type Props = {
  data: Array<ItemData>,
}
const colorLine: string = "rgba(255, 255, 255, 0.10)";
const axisStyle: * = {
  fontSize: "12px",
  fill: "rgba(217, 217, 217, 0.70)",
};
const TickComponent = (p) => <XAxisContent {...p} colorLine="#DDDF4B" axisStyle={axisStyle} />;

const commonLineProps: * = {
  filter: "url(#glowShadow)",
  type: "monotone",
  strokeWidth: 4,
  strokeLinecap: "round",
  dot: false,
  activeDot: false,
  isAnimationActive: false,
};

const getFallbackData: () => ItemData[] = () => [
  { amount: 0, date: "" },
  { amount: 0, date: "" },
];

const Chart = ({
  data,
}: Props) => {
  const theme = useTheme();
  const axisStyleLeft: * = {
    fontSize: "12px",
    fontWeight: "700",
    fill: "#CDDC39",
  };
  const axisStyleRight: * = {
    fontSize: "12px",
    fontWeight: "700",
    fill: "#836FA9",
  };

  const [lines] = useState<LineInfoType[]>([
    {
      hide: false,
      label: "Commission",
      labelLegend: "Commission",
      dataKey: "amount",
      colors: ["#CDDC39", "#DDDF4B"],
      yAxisId: "left",
    },
    {
      hide: false,
      label: "Leads",
      labelLegend: "Leads",
      dataKey: "quantity",
      colors: ["#836FA9", "#6247AA"],
      yAxisId: "right",
    },
  ]);

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={350} minWidth={300}>
      <ComposedChart
        data={data.length ? data : getFallbackData()}
        margin={{
          top: 30, right: 60, left: 0, bottom: 30,
        }}
      >
        <Tooltip
          contentStyle={{
            backgroundColor: theme?.palette.background.default,
            borderRadius: theme?.shape.cardBorderRadius,
            padding: "8px 12px",
            boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.2)",
            border: "none",
            marginTop: "0",
          }}
          wrapperStyle={{
            outline: "none",
            zIndex: theme?.zIndex.tooltip,
          }}
          labelFormatter={formatDate}
          cursor={{ stroke: colorLine }}
        />
        <CartesianGrid vertical={false} stroke={colorLine} />
        <XAxis
          padding={{
            left: 20, right: 20,
          }}
          dataKey="date"
          tickFormatter={formatDate}
          tick={TickComponent}
          axisLine={{ stroke: colorLine }}
          tickLine={false}
          tickSize={20}
        />
        <Legend
          wrapperStyle={{ top: 15, left: 0 }}
          verticalAlign="top"
          align="center"
        />

        <defs>
          <GlowShadowFilter id="glowShadow" glowSize={7} />
          {lines.map(({ dataKey, colors }: LineInfoType): Node => (
            <LinearGradient key={dataKey} id={dataKey} colors={colors} lineOffset={0.04} />
          ))}
        </defs>
        <YAxis
          width={60}
          tickLine={false}
          axisLine={false}
          tick={axisStyleLeft}
          tickFormatter={formatNumber}
          yAxisId="left"
        />
        <YAxis
          width={50}
          tick={axisStyleRight}
          tickFormatter={formatNumber}
          tickLine={false}
          axisLine={false}
          yAxisId="right"
          orientation="right"
        />
        {lines.map((props: LineInfoType): Node => (
          <Line
            key={props.dataKey}
            {...commonLineProps}
            {...props}
            dataKey={props.dataKey}
            stroke={`url(#${props.dataKey})`}
            name={props.label}
            yAxisId={props.yAxisId}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Chart;
