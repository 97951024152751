// @flow
import React, { type Node } from "react";
import {
  Box, Typography,
} from "@mui/material";
import { makeStylesTyped } from "../../helpers/generators";

const useStyles = makeStylesTyped((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    borderTop: `solid 1px ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  policy: {
    paddingRight: theme.spacing(1),
  },
  terms: {
    paddingRight: theme.spacing(1),
  },
  copyright: {},
}));

function Footer(): Node {
  const classes = useStyles();
  return (
    <Box className={classes.footer} gap={0.5}>
      <Typography className={classes.copyright} variant="body2">
        Copyright © TOGETHERADS PTE. LTD,
      </Typography>
      <Typography className={classes.copyright} variant="body2">
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}
export default Footer;
