const prefix = "/api/v1/bank";

export default (baseUrl) => ({
  baseUrl,
  links: {
    restorePassword: "/restorePassword",
    login: "/login",
    logout: "/logout",
    dashboard: "/dashboard",
  },
  endpoints: {
    get: {
      getActiveBrands: `${baseUrl}${prefix}/activebrands`,
      getDropdownList: `${baseUrl}${prefix}/dropdown`,
      getPostbacks: `${baseUrl}/api/v1/defaultPostback`,
    },
    post: {
      login: `${baseUrl}/api/auth/login`,
      autologin: `${baseUrl}/api/auth/autologin`,
      restorePassword: `${baseUrl}/api/auth/restorePassword`,
      changePassword: `${baseUrl}/api/auth/changePassword`,
      getDashboardData: `${baseUrl}/api/v1/invoiceTransactions`,
      downloadTransactionReport: `${baseUrl}/api/v1/exportInvoiceTransactions`
    },
    delete: {},
    redirect: {},
  },
});
