import React from "react";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import environment from "environment";
import {
  Typography, Button, Box, TextField, Container,
} from "@mui/material";
import { addNotification } from "@fas/cpa-state-manager/redux/actions/notifications";
import { useSearchParam } from "@fas/cpa-state-manager/hooks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
// eslint-disable-next-line import/max-dependencies
import { changePassword } from "../../services/request";

const schema = Joi.object({
  token: Joi.any(),
  password: Joi.string().required(),
  confirmation: Joi
    .string()
    .required()
    .equal(Joi.ref("password"))
    .options({ messages: { "any.only": "Confirm password doesn't match" } }),
})
  .messages({
    "string.empty": "This field is required",
    "string.email": "Email is not valid",
  });

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSearchParam("token");

  const {
    control,
    handleSubmit,
    setError,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      password: "",
      confirmation: "",
      token,
    },
  });

  const onSubmit = async (data: { password: string, confirmation: string, token: string }) => {
    try {
      const response = await changePassword(data);
      dispatch(addNotification({ severity: "success", message: response.data.message }));
      navigate(environment.links.login);
    }
    catch (error) {
      if (Array.isArray(error?.response?.data?.errors)) {
        error.response.data.errors.forEach((e) => {
          if (e.field) {
            setError(e.field, { message: e.error });
          }
        });
      }
      else {
        const message = error?.response?.data?.message || "Error";
        dispatch(addNotification({ severity: "error", message }));
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
      }}
    >
      <Box
        gap={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        width={1}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit(onSubmit)();
          }
        }}
      >
        <Typography variant="h2" noWrap sx={{ fontWeight: 700 }}>
          Change Password
        </Typography>
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e);
                trigger("confirmation"); // Trigger validation for confirmation field
              }}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              size="small"
              label="Password"
              placeholder="Input your password"
              type="password"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="confirmation"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              size="small"
              label="Confirm Password"
              placeholder="Confirm your password"
              type="password"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Button
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          data-testid="change-password-save"
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
        >
          Change Password
        </Button>
      </Box>
    </Container>
  );
};

export default ChangePassword;
