// @flow
// eslint-disable-next-line no-unused-vars
import React from "react";
import { createTheme } from "@mui/material";
import { darken } from "@mui/material/styles";

type BaseTheme = {
  breakpoints: {
    down: *,
    up: *,
  },
  zIndex: *,
  palette: {
    divider: string,
    error: *,
    common: {
      white: string,
      black: string,
    },
  },
  breakpoints: *,
  typography: *,
};

type OverrideTheme = {|
  spacing: (number) => number,
  theme: string,
  props: *,
  typography: *,
  shape: *,
  buttons: *,
  palette: {|
    mode: *,
    background: *,
    text: *,
    icons: *,
    gradient: {
      start: string,
      stop: string,
    },
    error: *,
    success: *,
    secondary: *,
    primary: *,
    transparent: {
      default: string,
      text: string,
      disabled: string,
      light: string,
      dark: string,
      main: string,
      full: string,
      contrast: string,
    },
  |},
  components: *,
  breakpoints: { values: { [string]: number }, [string]: (*, *) => * },
|};

export type DefaultTheme = {
  ...BaseTheme,
  ...OverrideTheme,
  palette: {
    ...$PropertyType<BaseTheme, "palette">,
    ...$PropertyType<OverrideTheme, "palette">,
  },
};

const breakpoints: * = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1444,
    xl: 1536,
  },
};

const colors = {
  main: "#FFF",
  primary: "#DDDF4B",
  secondary: "#DAE0E3",
  mutted: "rgba(217, 217, 217, 0.70)",
  dark: "#2D2E2F",
  transparent: "rgba(255, 255, 255, 0.1)",
  error: "#E32116",
  success: "#169873",
};

const theme: * = createTheme({
  breakpoints,
});

const themeDefaultObject: OverrideTheme = {
  theme: "default",
  props: {},
  spacing: (f: number): number => f * 10,
  breakpoints,
  typography: {
    fontFamily: "Wix Madefor Display",
    fontStyle: "normal",
    h1: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(50),
      lineHeight: 1.4,
    },
    h4: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1.428,
    },
    h5: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1.428,
    },
    h6: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.555,
    },
    button: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: "8px 16px",
      color: colors.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      textTransform: "uppercase",
      textDecoration: "none",
    },
    mainButton: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: "6px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      textTransform: "uppercase",
      backgroundColor: colors.primary,
      color: colors.dark,
      textDecoration: "none",
      border: `2px solid ${colors.dark}`,
      borderRadius: "0",
      "&:hover": {
        border: "2px solid #FEFFB8",
        boxShadow: "0px 4px 20px 0px rgba(221, 223, 75, 0.50)",
        backgroundColor: colors.primary,
      },
      "&:disabled": {
        color: colors.main,
        background: "rgba(218, 224, 227, 0.50)",
      },
    },
    outlinedButton: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: "8px 16px",
      color: colors.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      textTransform: "uppercase",
      border: `2px solid ${colors.main}`,
      textDecoration: "none",
    },
    outlinedMainButton: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: "8px 16px",
      color: colors.primary,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      textTransform: "uppercase",
      border: `2px solid ${colors.primary}`,
      textDecoration: "none",
      "&:hover": {
        background: "rgba(255, 255, 255, 0.1)",
      },
    },
    body: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
    bodyBold: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: 700,
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.333,
    },
    bodySmallBold: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.428,
      fontWeight: 700,
    },
    bodyExtraSmallBold: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.333,
      fontWeight: 700,
    },
    tableMob: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(9),
      lineHeight: 2,
    },
    tableMobBold: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(9),
      lineHeight: 2,
    },
  },
  shape: {
    borderRadius: 0,
    cardBorderRadius: 0,
  },
  buttons: {
    main: {},
    secondary: {},
  },
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      light: "rgba(255, 255, 255, 0.10)",
    },
    primary: {
      main: colors.primary,
      light: "rgb(55,55,33)",
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      main: colors.main,
      secondary: colors.secondary,
      mutted: colors.mutted,
      dark: colors.dark,
    },
    icons: {},
    gradient: {},
    error: {
      main: colors.error,
      light: colors.error,
      dark: colors.error,
    },
    success: {
      main: colors.success,
      light: colors.success,
      dark: colors.success,
    },
    transparent: {
      default: "rgba(255, 255, 255, 0.8)",
      text: "rgba(255, 255, 255, 0.5)",
      disabled: "rgba(255, 255, 255, 0.38)",
      light: "rgba(255, 255, 255, 0.08)",
      dark: "rgba(255, 255, 255, 0.6)",
      main: "rgba(255, 255, 255, 0.5)",
      full: "rgba(255, 255, 255, 0)",
      contrast: "rgba(0, 0, 0, 0.65)",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
        },
        standardSuccess: {
          backgroundColor: colors.success,
        },
        standardError: {
          backgroundColor: colors.error,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          border: "none",
        },
        tooltip: {
          maxHeight: "40vh",
          whiteSpace: "break-spaces",
          overflowWrap: "anywhere",
          overflowY: "auto",
          display: "flex",
          alignItems: "flex-start",
          padding: 20,
          border: "1px solid rgba(218, 224, 227, 0.40)",
          background: "#1a1a1a",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: colors.main,
          "& button": {
            marginLeft: 10,
            position: "sticky",
            top: 0,
          },

          margin: 0,
          boxShadow: "0px 0px 40px 0px rgba(221, 223, 75, 0.40)",
          "& .MuiIconButton-root": {
            padding: 0,
            marginLeft: "12px",
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 400,
            lineHeight: 1.333,
          },
          "& .MuiSvgIcon-root": {
            fontSIze: "24px",
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: "fit-content",
          borderSpacing: "0 8px",
          borderCollapse: "separate",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          color: colors.main,
          background: "rgba(255, 255, 255, 0.05)",
          backgroundBlendMode: "color-dodge",
          rowSpacing: "8px",

          "&.MuiTableRow-hover:hover": {
            backgroundColor: "rgba(221, 223, 75, 0.15)",
          },
          "& .MuiTableCell-head": {
            color: colors.mutted,
            fontWeight: 700,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: colors.main,
          fontWeight: 400,
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 1.5,

          border: "none",
          textAlign: "center",
          whiteSpace: "nowrap",
          padding: "0 8px",
          height: "32px",
          borderBottom: "none",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInput-root": {
            marginTop: "0px",
          },
          "& .MuiFormHelperText-root.Mui-error": {
            color: colors.main,
            lineHeight: 1.333,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: "rgba(45, 46, 47, 0.70)",
          background: colors.primary,
          padding: "0px 4px ",
          textTransform: "uppercase",
          transform: "none",
          "&.Mui-focused": {
            color: "rgba(45, 46, 47, 0.70)",
          },
          "&.Mui-error": {
            color: colors.main,
          },
          fontWeight: 700,
          fontSize: "12px",
          lineHeight: 1,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          position: "absolute!important",
          left: "8px!important",
          top: "-8px!important",
          zIndex: 1,
          color: "rgba(45, 46, 47, 0.70)",
          background: colors.primary,
          padding: "0px 4px ",
          textTransform: "uppercase",
          transform: "none",
          "&.Mui-focused": {
            color: "rgba(45, 46, 47, 0.70)",
          },
          "&.Mui-error": {
            background: theme.palette.error.main,
          },
          fontWeight: 700,
          fontSize: "12px",
          lineHeight: 1,
          "& > p": { // for postbacks
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: 1,
            color: "rgba(45, 46, 47, 0.70)",
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          border: "1px solid rgba(218, 224, 227, 0.40)",
          background: "rgba(0, 0, 0, 0.20)",
          backgroundBlendMode: "multiply",
          padding: "9px 8px",
          color: colors.main,

          "& .MuiInputAdornment-positionEnd": {
            margin: "0 10px 0 0",
          },
          "& .MuiInputAdornment-positionStart": {
            margin: "0 0 0 10px",
          },
          "&.Mui-disabled": {
            color: colors.main,
            ".MuiInputAdornment-positionStart, .MuiInputAdornment-positionEnd": {
              color: colors.mutted,
            },
          },
          "&.Mui-focused": {
            borderColor: colors.primary,
            color: colors.primary,
            "& input, & .MuiSvgIcon-root": {
              color: colors.primary,
            },
          },
          "&.Mui-error": {
            borderColor: colors.error,
            color: colors.error,
            "& .MuiInputAdornment-positionEnd, & .MuiInputAdornment-positionStart": {
              color: colors.error,
            },
          },
        },
        input: {
          fontSize: "14px",
          lineHeight: 1.42,
          padding: 0,
          color: "inherit",
          "&.Mui-disabled": {
            cursor: "not-allowed",
            color: "inherit",
            "WebkitTextFillColor": "inherit",
          },
          // "&::placeholder, &::-webkit-input-placeholder": {
          //   color: colors.mutted,
          //   opacity: "1",
          // },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.mutted,
          margin: "4px 0 0",
          "&:empty": {
            display: "none",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #121212 inset",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: colors.primary,
            color: colors.dark,
            boxShadow: "0px 8px 20px 0px rgba(221, 223, 75, 0.60)",

            "& .MuiSvgIcon-root": {
              fill: colors.dark,
            },
          },
          "& .MuiMenuItem-root.Mui-selected:hover": {
            backgroundColor: darken(colors.primary, 0.2),
            color: colors.dark,

            "& .MuiSvgIcon-root": {
              fill: colors.dark,
            },
          },
          "& .MuiMenuItem-root .MuiTypography-root": {
            textTransform: "uppercase",
            fontSize: theme.typography.pxToRem(14),
            lineHeight: 1.428,
            fontWeight: 700,
          },
          "& .MuiMenuItem-root": {
            padding: "16px",
            marginTop: "8px",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiCheckbox: {
      defaultProps: {
      },
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "transparent" },
          style: {
            background: "rgba(255, 255, 255, 0.08)",
            fontWeight: 700,
            paddingTop: "12px",
            paddingBottom: "12px",
            minWidth: "50px",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          minHeight: 40,
          "& .MuiTouchRipple": {
            background: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          color: "rgba(255,255,255, 0.65)",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& label": {
          },
          "& label.Mui-focused": {
            fontWeight: 700,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          gap: "8px",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: "40px",
          width: "40px",
          padding: "8px",
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 1,
          fontWeight: 600,
          color: colors.primary,
          border: `2px solid ${colors.primary}`,
          margin: 0,
          borderRadius: "0",
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: colors.primary,
            color: colors.dark,
          },
          "&:hover": {
            backgroundColor: "rgba(221, 223, 75, 0.20)",
            color: colors.main,
          },
        },
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          "& .MuiPickersDay-root": {
            backgroundColor: colors.transparent,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "transparent",
          boxShadow: "none",
          border: "none",
          "&.Mui-expanded": {
            margin: "0",
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: "unset",
          "&.Mui-expanded": {
            minHeight: "unset",
            marginBottom: "12px",
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: "0",
          },
          "& .MuiTypography-root": {
            color: colors.main,
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 700,
            lineHeight: 1.555,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0",
          "& .MuiInput-root, & .MuiCoreUi-wrapperDatePicker .MuiInputBase-root": {
            background: colors.transparent,
          },
        },
      },
    },
  },
};

export default themeDefaultObject;
