// @flow
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import environment from "environment";
import { Box, Button, TextField } from "@mui/material";
import { addNotification } from "@fas/cpa-state-manager/redux/actions/notifications";
import { useDispatch } from "react-redux";
import { login } from "../../services/request";

const schema = Joi.object({
  login: Joi.string().required().email({ tlds: false }).messages({
    "string.empty": "This field is required",
    "string.email": "Email is not valid",
  }),
  password: Joi.string().required().messages({
    "string.empty": "This field is required",
  }),
});

type Props = {
  onNext: ({ expires: number, token: string }) => mixed,
};

const AuthField = ({ onNext }: Props) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      login: "",
      password: "",
    },
  });

  const onSubmit = async (data: { login: string, password: string }) => {
    try {
      const response = await login(data);
      onNext(response.data.payload);
    }
    catch (error) {
      if (Array.isArray(error?.response?.data.errors)) {
        error?.response?.data.errors.forEach((e) => {
          if (["login", "password"].includes(e.field)) {
            setError(e.field, { message: e.error });
          }
        });
      }
      else {
        const message = error?.response?.data?.message || "Error";
        dispatch(addNotification({ severity: "error", message }));
      }
    }
  };

  return (
    <Box
      onKeyPress={(e) => {
        if (e.charCode === 13) handleSubmit(onSubmit)();
      }}
      gap={1}
      display="flex"
      flexDirection="column"
      width={1}
    >
      <Controller
        name="login"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="dense"
            required
            fullWidth
            autoFocus
            label="Email"
            placeholder="Input Your Email"
            size="small"
            type="text"
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="dense"
            required
            fullWidth
            size="small"
            label="Password"
            placeholder="Input your password"
            type="password"
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        name="next"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
      >
        <Box width="100%">Log In</Box>
      </Button>
      <Button
        data-testid="redirect-restore-password"
        variant="text"
        component="a"
        size="small"
        color="primary"
        href={environment.links.restorePassword}
      >
        Forgot password
      </Button>
    </Box>
  );
};

export default AuthField;
