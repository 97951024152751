// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box, Container, Typography,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStylesTyped } from "../../helpers/generators";
import { isAuthenticated, setAuthToken } from "../../services/request";
import AuthField from "./AuthField";
import { setAuthDataSaga } from "../../actions/auth";
import { COMPANIES_LOGIN } from "../../helpers/constants";

type Props = {
  location: *,
  history: *,
}

const useStyles = makeStylesTyped(() => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  title: {
    fontWeight: 700,
  },
}));

const LoginOneStep: StatelessFunctionalComponent<Props> = ({ location, history }: Props) => {
  const dispatch = useDispatch();
  const onLogin = (params) => dispatch(setAuthDataSaga(params));
  const classes = useStyles();

  if (isAuthenticated()) {
    const { from } = location.state || { from: { pathname: "/" } };
    return <Navigate to={from} />;
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Typography variant="h2" className={classes.title}>
            Sign In
          </Typography>
          <AuthField onNext={(data: {expires: number, token: string}) => {
            setAuthToken(data.token, new Date(data.expires * 1000));
            const { from } = location.state || { from: { pathname: "/" } };
            onLogin({
              history,
              companies: COMPANIES_LOGIN,
              redirect: from.pathname,
              expires: new Date(data.expires * 1000),
            });
          }}
          />
        </Box>
      </>
    </Container>
  );
};

export default LoginOneStep;
