// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box, Typography,
} from "@mui/material";
import InputCode from "./InputCode";

type Props = {}

const PostbackUrls: StatelessFunctionalComponent<Props> = ({}: Props) => (
  <Box>
    <Box display="flex" justifyContent="space-between" pb={2}>
      <Typography variant="h5">Postback URLs</Typography>
    </Box>
    <InputCode />
  </Box>
);

export default PostbackUrls;
