// @flow
import defaultObject from "./default";
import { type DefaultTheme } from "./default";

const themeObject = {
  defaultObject,
};

export default themeObject;
export type { DefaultTheme };
