// @flow
import RequestService from "@fas/ui-framework/lib/services/request";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { Cookies } from "react-cookie";
import environment from "environment";

/**
 * Get authorization token
 * @returns {string} token or empty string
 */
export function getAuthToken(): string {
  const data: {token?: string} = new Cookies().get("authToken") || {};
  return data.token || "";
}

export function setAuthToken(token: string, expires: Date) {
  new Cookies().set("authToken", { token }, { expires });
}

/**
 * Get auth product company to select in two step login form
 * @returns {string[]} product company or empty array
 */
export function getAuthProductCompany(): string[] {
  const data: {productCompanies?: string[]} = new Cookies().get("authData") || {};
  return data.productCompanies || [];
}

/**
 * Get first auth product company
 * if we select one company in login form, but if we select all company we'll get an undefined
 * @returns {string|undefined} first product company or undefined if we select all company
 */
export function getFirstAuthProductCompany(): string | void {
  const companies: string[] = getAuthProductCompany();
  return companies.length === 1 ? companies[0] : undefined;
}

/**
 * Get auth product company to select in two step login form
 * @returns {string[]} product company or empty array
 */
export function getAuthDepartments(): string[] {
  const data: {departments?: string[]} = new Cookies().get("authData") || {};
  return data.departments || [];
}

/**
 * Set auth data
 * @param {string} productCompanies auth company
 * @param {string} departments auth company
 * @param {Date} expires date
 * @returns {void}
 */
export function setAuthData(productCompanies: string[], departments: string[], expires: Date) {
  new Cookies().set("authData", { productCompanies, departments }, { expires });
}

const getAuthorizationHeaders: () => { [key: string]: string } = () => ({
  Authorization: `Bearer ${getAuthToken()}`,
});

function redirect() {
  logout();
  window.location.href = "/login";
}

/**
 * Request service for all request
 * @type {Request}
 */
export const requestService: RequestService = new RequestService({
  withCredentials: false,
  timeout: 30000,
  // $FlowFixMe
  customAttr: { getAuthorizationHeaders, redirect },
});
const authRequestService: RequestService = new RequestService({
  timeout: 30000,
  // $FlowFixMe
  customAttr: { ignoreAuthorization: true, redirect: () => {} },
});

type AuthResponse = {
  message: string,
  payload: {
    token: string,
    expires: number,
  },
  status: number,
}

export function login(params: {login: string, password: string}): PromiseResponse<mixed, AuthResponse> {
  return authRequestService.post(environment.endpoints.post.login, params);
}
export function autologinWithPassword(params: {
  login: string, token: string,
}): PromiseResponse<mixed, AuthResponse> {
  return authRequestService.post(environment.endpoints.post.autologin, params);
}

export function restorePassword(params: {
  email: string,
}): PromiseResponse<mixed, AuthResponse> {
  return authRequestService.post(environment.endpoints.post.restorePassword, params);
}
export function changePassword(params: {
  password: string, confirmation: string, token: string,
}): PromiseResponse<mixed, AuthResponse> {
  return authRequestService.post(environment.endpoints.post.changePassword, params);
}

export function logout() {
  new Cookies().remove("authToken");
  new Cookies().remove("authData");
}

export function isAuthenticated(): boolean {
  return Boolean(getAuthToken()) && getAuthDepartments().length > 0 && getAuthProductCompany().length > 0;
}
