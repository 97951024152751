// @flow
import React, { type StatelessFunctionalComponent, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { FileCopyOutlined } from "@mui/icons-material";
import CopyToClipboard from "../CopyToClipboard";
import { getPostbacks } from "../../services/postbackApi";

type Props = {}
const PostbackUrls: StatelessFunctionalComponent<Props> = () => {
  const [postback, setPostback] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getPostbacks({})
      .then(({ data } = {}) => {
        setPostback(data?.defaultPostback);
        setIsLoading(false);
      })
      .catch(() => {
        setPostback("");
      });
  }, []);

  const handleCopyUrl: (value: string) => void = (value) => {
    navigator.clipboard.writeText(value);
  };
  return (
    <Box width="100%">
      <TextField
        fullWidth
        label="Tracking code"
        size="small"
        variant="outlined"
        value={postback}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <CopyToClipboard copy={() => handleCopyUrl(postback)}>
                {({ copy }) => <IconButton disabled={isLoading} size="small" onClick={copy}><FileCopyOutlined /></IconButton>}
              </CopyToClipboard>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default PostbackUrls;
