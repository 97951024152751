// @flow
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import {
  Typography, Button, Box, TextField, Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSearchParam } from "@fas/cpa-state-manager/hooks";
import { useDispatch } from "react-redux";
import { autologinWithPassword, setAuthToken } from "../../services/request";
import { setAuthDataSaga } from "../../actions/auth";
// eslint-disable-next-line import/max-dependencies
import { COMPANIES_LOGIN } from "../../helpers/constants";

// Joi validation schema
const schema = Joi.object({
  login: Joi.string().required().email({ tlds: false }).messages({
    "string.empty": "Email is required",
    "string.email": "Invalid email address",
  }),
  token: Joi.any(),
});

const Autologin = () => {
  const token = useSearchParam("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      login: "",
      token,
    },
  });

  const onLogin = (params) => dispatch(setAuthDataSaga(params));

  const onSubmit = async (data: { login: string, token: string }) => {
    try {
      const response = await autologinWithPassword(data);
      const { payload } = response.data;
      setAuthToken(payload.token, new Date(payload.expires * 1000));
      onLogin({
        history: navigate,
        companies: COMPANIES_LOGIN,
        redirect: "/",
        expires: new Date(payload.expires * 1000),
      });
    }
    catch (error) {
      if (Array.isArray(error?.response?.data?.errors)) {
        error.response.data.errors.forEach((e) => {
          if (e.field === "login") {
            setError(e.field, { message: e.error });
          }
        });
      }
      else {
        const message = error?.response?.data?.message || "Unexpected error occurred";
        setError("login", { message });
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
      }}
    >
      <Box
        gap={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        width={1}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit(onSubmit)();
          }
        }}
      >
        <Typography variant="h2" noWrap sx={{ fontWeight: 700 }}>
          Autologin
        </Typography>
        <Controller
          name="login"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              autoFocus
              label="Email"
              placeholder="Input Your Email"
              size="small"
              type="text"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          name="next"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
        >
          <Box width="100%">Log In</Box>
        </Button>
      </Box>
    </Container>
  );
};

export default Autologin;
