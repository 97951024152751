// @flow
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import {
  Typography, Button, Box, TextField, Container,
} from "@mui/material";
import environment from "environment";
import { useDispatch } from "react-redux";
import { addNotification } from "@fas/cpa-state-manager/redux/actions/notifications";
import { restorePassword } from "../../services/request";

const schema = Joi.object({
  email: Joi.string().required().email({ tlds: false }).messages({
    "string.empty": "This field is required",
    "string.email": "Email is not valid",
  }),
});

const RestorePassword = () => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: { email: string }) => {
    try {
      const response = await restorePassword(data);
      dispatch(addNotification({ severity: "success", message: response.data.message }));
    }
    catch (error) {
      const message = error?.response?.data?.message || "Error";
      if (Array.isArray(error?.response?.data.errors)) {
        error?.response?.data.errors.forEach((e) => {
          if (e.field === "email") {
            setError("email", { message: e.error });
          }
        });
      }
      else {
        dispatch(addNotification({ severity: "error", message }));
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
      }}
    >
      <Box
        gap={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        width={1}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit(onSubmit)();
          }
        }}
      >
        <Typography variant="h2" noWrap sx={{ fontWeight: 700 }}>
          Restore Password
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="dense"
              required
              fullWidth
              autoFocus
              label="Email"
              placeholder="Input Your Email"
              size="small"
              type="text"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Button
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          data-testid="restore-password-save"
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
        >
          Restore Password
        </Button>
        <Button
          fullWidth
          data-testid="redirect-login"
          variant="text"
          component="a"
          size="small"
          color="primary"
          href={environment.links.login}
        >
          Back to Log In
        </Button>
      </Box>
    </Container>
  );
};

export default RestorePassword;
